import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon, FontAwesomeLayers } from "@fortawesome/vue-fontawesome";
import { faFile as farFile } from "@fortawesome/pro-regular-svg-icons";
import {
    faWebhook,
    faExpand,
    faList,
    faTriangleExclamation,
    faLock,
    faBell,
    faChevronRight,
    faShieldHalved,
    faBars,
    faMagnifyingGlass,
    faTrashCan,
    faBulldozer,
    faExcavator,
    faRotateRight,
    faCircleXmark,
    faShieldCheck,
    faShieldExclamation,
    faShieldXmark,
    faTrashAlt,
    faComment,
    faSitemap,
    faGear,
    faBatteryEmpty,
    faTimes,
    faCalendar,
    faGauge,
    faCircleNotch,
    faMapLocationDot,
    faHouseUser,
    faPaperPlane,
    faTrash,
    faUserPlus,
    faEye,
    faEyeSlash,
    faWarehouse,
    faCopy,
    faMap,
    faTemperatureThreeQuarters,
    faCircleCheck,
    faLinkSlash,
    faDownload,
    faTruckFast,
    faGaugeHigh,
    faLocationCrosshairs,
    faArchive,
    faTruck,
    faTrailer,
    faBus,
    faCar,
    faVanShuttle,
    faArrowUp,
    faExclamationCircle,
    faSpinner,
    faPrint,
    faCircle,
    faBoxArchive,
    faPen,
    faPlus,
    faLocationCrosshairsSlash,
    faSkullCrossbones,
    faSatellite,
    faChevronUp,
    faFile,
    faChevronDown,
    faCheck,
    faArrowLeft,
    faFilePen,
    faUsers,
    faFileArrowUp,
    faImage,
    faRoute,
    faPaperclip,
    faTruckContainer,
    faArrowUpRightFromSquare,
    faTirePressureWarning,
    faTire,
    faSignalStream,
    faSignalStreamSlash,
    faHourglass,
    faEllipsisVertical,
    faChartMixed,
    faBellSlash,
    faSensor,
    faSort,
    faQuestionCircle,
    faGarage,
    faMicrochip,
    faSimCard,
    faUser,
    faCircleInfo,
    faClock,
    faBolt,
    faWrench,
    faKey,
    faTruckFlatbed,
} from "@fortawesome/pro-solid-svg-icons";

// For some reason this cast seems to be required.
// https://github.com/FortAwesome/angular-fontawesome/issues/125
library.add(
    faHouseUser,
    faList,
    faChartMixed,
    faChevronRight,
    faTruckFlatbed,
    faCircleCheck,
    faLocationCrosshairsSlash,
    faFile,
    faTruckContainer,
    faImage,
    faExcavator,
    faBulldozer,
    faShieldExclamation,
    faShieldXmark,
    faShieldHalved,
    faArrowUpRightFromSquare,
    faChevronDown,
    faChevronUp,
    faSignalStream,
    faSignalStreamSlash,
    faBars,
    faMagnifyingGlass,
    faTrash,
    faCircleXmark,
    faUsers,
    faTrashCan,
    faTrashAlt,
    faComment,
    faGear,
    faSitemap,
    faBatteryEmpty,
    faTimes,
    faRoute,
    faWebhook,
    faCalendar,
    faGauge,
    faPaperPlane,
    faTemperatureThreeQuarters,
    faLinkSlash,
    faDownload,
    faTruckFast,
    faGaugeHigh,
    faLocationCrosshairs,
    faArchive,
    faTruck,
    faShieldCheck,
    faTrailer,
    faBus,
    faCar,
    faVanShuttle,
    faArrowUp,
    faExclamationCircle,
    faCopy,
    farFile,
    faSpinner,
    faPrint,
    faCircle,
    faBoxArchive,
    faRotateRight,
    faPen,
    faPlus,
    faBell,
    faLock,
    faExpand,
    faSkullCrossbones,
    faSatellite,
    faTriangleExclamation,
    faCircleNotch,
    faUserPlus,
    faEye,
    faEyeSlash,
    faWarehouse,
    faMap,
    faMapLocationDot,
    faCheck,
    faArrowLeft,
    faFilePen,
    faFileArrowUp,
    faPaperclip,
    faMagnifyingGlass,
    faTirePressureWarning,
    faTire,
    faTemperatureThreeQuarters,
    faHourglass,
    faEllipsisVertical,
    faBellSlash,
    faTriangleExclamation,
    faSensor,
    faSort,
    faQuestionCircle,
    faMicrochip,
    faGarage,
    faSimCard,
    faUser,
    faCircleInfo,
    faWrench,
    faTire,
    faClock,
    faBolt,
    faWrench,
    faKey
);

export { FontAwesomeIcon, FontAwesomeLayers };
