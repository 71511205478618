{
    "general": {
        "submit": "Submit",
        "imei": "IMEI",
        "powered-by": "Powered by",
        "access_level": "Access Level",
        "managed_by": "Managed By {value}",
        "clear-screen": "Clear Screen",
        "reconnect": "Reconnect",
        "general": "General",
        "alerts": "Alerts",
        "sim_card": "SIM Card",
        "rl-automotive": "RL Automotive",
        "serial_number": "Serial Number",
        "you": "You",
        "remote": "Remote",
        "contacts": "Contacts",
        "address": "Address",
        "readings": "No Readings | {count} Reading | {count} Readings",
        "provider": "Provider",
        "show_more": "Show More",
        "next": "Next",
        "previous": "Previous",
        "checklist_faults": "{ count } Fault identified in checklist | { count } Faults identified in checklist",
        "show_less": "Show Less",
        "checksum_calculator": "Checksum Calculator",
        "checksum_calculator_prompt": "Adding a sensor to a vehicle? Use the {0}",
        "date": "Date",
        "role": "Role",
        "sort_by": "Sort by",
        "hours": "hours",
        "volts": "V",
        "voltage": "voltage",
        "alert_low_pressure": "Critical",
        "alert_high_temperature": "Alert(High Temperature)",
        "warning": "Warning",
        "warnings": "No Warnings | { count } Warning | { count } Warnings",
        "maintenance": "Maintenance",
        "unassigned": "Unassigned",
        "stale": "Stale",
        "offline": "Offline",
        "okay": "Okay",
        "print": "print",
        "update_profile": "Update Profile",
        "signed_in_as": "Signed In As",
        "vin": "VIN",
        "all": "All",
        "no_alert": "OK",
        "sensor_id": "Sensor ID",
        "telematics_units": "Telematics Units",
        "start": "Start",
        "last_seen": "Last Seen",
        "last_reported": "last reported",
        "confirm": "Confirm",
        "create": "Create",
        "from": "From",
        "admin": "Admin",
        "member": "User",
        "permissions": "Permissions",
        "driver": "Driver",
        "selected-vehicle-count": "{0} vehicles of {1} selected",
        "last_time_seen": "Last time seen",
        "last_time_seen_desc": "Last time seen (descending)",
        "include_inactive": "Include inactive",
        "end": "End",
        "home": "Home",
        "edit": "Edit",
        "reports": "Reports",
        "kilometers": "Kilometres",
        "distance": "Distance",
        "organisation": "Organisation",
        "organisations": "Organisations",
        "events": "Events",
        "changelog": "Changelog",
        "environmental": "Environmental",
        "no_data": "No data received",
        "notes": "Notes",
        "update": "Update",
        "none": "None",
        "unknown": "Unknown",
        "not_set": "Not Set",
        "username": "Username",
        "password": "Password",
        "fleet": "Fleet",
        "fleet_name": "Fleet Name",
        "registration": "Registration",
        "vehicles": "Vehicles",
        "settings": "Settings",
        "cancel": "Cancel",
        "id": "ID",
        "close": "Close",
        "select_one": "Please select one",
        "email": "Email",
        "phone_number": "Phone Number",
        "celcius": "Celcius",
        "coming_soon": "Coming Soon",
        "farenheit": "Farenheit",
        "search": "Search...",
        "confirm_delete": "Are you sure you wish to delete {value}?",
        "name": "Name",
        "any": "Any",
        "delete": "Delete",
        "language": "Language",
        "type": "Type",
        "yes": "Yes",
        "no": "No",
        "archived": "Archived",
        "pressure": "Pressure",
        "cold_pressure": "Cold Pressure",
        "iccid": "ICCID",
        "emails": "Email1, Email2, ....",
        "grams": "grams",
        "request_quote": "Request Quote",
        "members": "Users",
        "url": "URL",
        "latitude": "Latitude",
        "longitude": "Longitude",
        "timestamp": "Timestamp",
        "temperature": "Temperature",
        "no_results": "No results found",
        "no_results_subheading": "No results could be found matching your search criteria",
        "refine_search": "Please refine your search",
        "refine_search_subheading": "Search for a vehicle registration or select a specific Fleet/Depot",
        "created_on": "Created On",
        "created_on_date": "Created on { date }",
        "registration_id": "Registration/ID",
        "position": "Position",
        "vehicle": "Vehicle",
        "download": "Download",
        "wheel_position": "Wheel Position",
        "select_your_option": "Select your option",
        "are_you_sure": "Are you sure?",
        "timescale": "Timescale",
        "timerange": "Time range",
        "add": "Add",
        "remove": "Remove",
        "comments": "Comments",
        "range": "Range",
        "integrations": "Integrations",
        "loading": "Loading...",
        "pressure_temp": "Pressure and Temperature",
        "tread_depth": "Tread Depths",
        "unlink": "Unlink",
        "overinflation": "Over-inflation",
        "tread": "Tread",
        "reboot": "Reboot",
        "location": "Location"
    },
    "trailer-braking": {
        "report": "Brake Performance Report",
        "latest": "Latest report",
        "date-range": "Report date range",
        "performance": "Brake performance value",
        "zf-derived": "The calculations are derived from ZF Group algorithms.",
        "dvsa-requirements": "This report is provided to satisfy reporting requirements of the DVSA.",
        "dvsa-specification": "UK specifc program requirements can be found in the DVSA publication 'Guide to Maintaining Roadworthiness', and the DVSA 'EBPMS Industry Standard Specifcations' document."
    },
    "vehicle-services": {
        "no-services-prompt": "No service data has been recorded for this vehicle",
        "no-services": "No services",
        "mot-expired": "Your MOT expired on"
    },
    "vehicle_alert_event": {
        "tyre_alert": "tyre pressure alert reported at position",
        "pressure_drop": "Tyre pressure dropped by {pressure} in the last hour",
        "cold_pressure_reported": "Cold pressure reported",
        "temperature_reported": "Temperature reported"
    },
    "organisation-hierachy": {
        "create-organisation": "Create Organisation",
        "create-organisation-modal-subtitle": "Organisations are used to provide separation for clients and users. Creating a sub-organisation gives the ability to limit access to vehicles."
    },
    "fleet-list": {
        "tyre-status": "Tyre Status",
        "toggle-vehicles-with-issues": "Only show vehicles with issues",
        "table-headings": {
            "mileage": "Mileage (Km)",
            "registration": "Registration",
            "service-interval": "Service (Km)",
            "service-interval-days": "Service (days)",
            "adblue-level": "AdBlue (%)",
            "fuel-level": "Fuel level (%)",
            "engine-oil-level": "Oil (%)",
            "depot": "Depot",
            "coolant": "Coolant",
            "brakes-warning": "Brake warning",
            "brakes-prewarning": "Brake pre-warning",
            "brakes-lining": "Brake linings",
            "battery": "Battery",
            "screen-wash": "Screen Wash",
            "mot-due": "MOT Due",
            "torque": "Torque",
            "tyres": "Tyres",
            "tyre-treads": "Tyre Tread"
        }
    },
    "fleet_pmi_report": {
        "heading": "Pre-maintenance inspection data. This may take some time to load",
        "wheel_position": "Wheel Position",
        "alert_type": "Alert type",
        "pressure": "Pressure (PSI)",
        "cold_pressure": "Cold Pressure (PSI)",
        "recommended_pressure": "Recommended Pressure (PSI)",
        "no_issues": "No Issues",
        "no_issues_prompt": "There were no recorded issues in the selected time span",
        "inflate_difference": "+/- Inflate (PSI)",
        "total_alerts": "alerts",
        "total_warnings": "warnings",
        "total_maintenance": "maintenance recommendations",
        "total_over_inflations": "over-inflations",
        "total_tyres": "tyres in total",
        "total_tyres_checked": "tyres checked",
        "total_vehicles_checked": "vehicles checked",
        "total_vehicles": "vehicles in total",
        "percentage_underinflated": "of tyres underinflated alerts",
        "percentage_overinflated": "of tyres overinflated alerts",
        "percentage_over_under_inflated": "overall fleet inflation"
    },
    "fleet_km_report": {
        "heading": "Speed and distance data. This may take some time to load",
        "registration": "Registration",
        "total_distance_travelled": "Total Distance Travelled (km)",
        "average_speed": "Average Speed (km/h)",
        "travelled_in_total": "Travelled in total"
    },
    "fleet_carbon_report": {
        "heading": "Emissions data. This may take some time to load",
        "registration": "Registration",
        "co2_emissions": "CO2 Emissions(g/km)",
        "co2_emitted": "CO2 Emitted (g)"
    },
    "services": {
        "1": "Latitude",
        "2": "Longitude",
        "3": "Altitude",
        "4": "1A Tire Pressure",
        "5": "1B Tire Pressure",
        "6": "1C Tire Pressure",
        "7": "1D Tire Pressure",
        "8": "2A Tire Pressure",
        "9": "2B Tire Pressure",
        "10": "2C Tire Pressure",
        "11": "2D Tire Pressure",
        "12": "3A Tire Pressure",
        "13": "3B Tire Pressure",
        "14": "3C Tire Pressure",
        "15": "3D Tire Pressure",
        "16": "4A Tire Pressure",
        "17": "4B Tire Pressure",
        "18": "4C Tire Pressure",
        "19": "4D Tire Pressure",
        "20": "5A Tire Pressure",
        "21": "5B Tire Pressure",
        "22": "5C Tire Pressure",
        "23": "5D Tire Pressure",
        "24": "6A Tire Pressure",
        "25": "6B Tire Pressure",
        "26": "6C Tire Pressure",
        "27": "6D Tire Pressure",
        "28": "Odometer",
        "29": "Speed",
        "30": "Fuel Level (Absolute)",
        "31": "Fuel Level Warning",
        "32": "Brakepads Warning",
        "33": "Engine Coolant Level Warning",
        "34": "Engine Coolant Temperature Warning",
        "35": "Engine Oil Level Warning",
        "36": "Battery Warning",
        "37": "Engine Coolant Temperature",
        "40": "Fuel Level",
        "41": "AdBlue Level",
        "42": "Engine Oil Level",
        "44": "Battery Voltage",
        "46": "Service Interval",
        "51": "Brakepads pre-warning",
        "150": "Brake Lining",
        "151": "Axle Load",
        "153": "Operational Hours"
    },
    "vehicle-emissions": {
        "fuel-used": "Fuel Used (Liters)",
        "carbon-emitted": "CO2 Emitted (Kg)",
        "emissions-since": "Emissions since {0}",
        "distance-travelled": "Distance Travelled (Km)"
    },
    "organisation-base-card": {
        "show-users": "Show Users",
        "hide-users": "Hide Users",
        "your-organisation": "Your Organisation",
        "no-login": "No Login Recorded",
        "no-users": "No Users in this organisation",
        "last-seen": "Last Seen {0}",
        "settings": "Settings",
        "change-password": "Change password",
        "permissions": "Permissions",
        "alerts": "Alerts",
        "delete-user": "Delete user"
    },
    "organisation_settings": {
        "no_profile_picture": "No Profile Picture",
        "setup_heading": "Advanced Organisation Setup",
        "setup_body": "Advanced organisation customization including logos and theme customization is available on request to RL Automotive.",
        "successfully_updates": "Successfully updated profile",
        "alerts_subheading": "Vehicles that {username} is subscribed to",
        "no_vehicles_content": "This user is not subscribed to any vehicles",
        "view_website": "View Website"
    },
    "errors": {
        "generic_unexpected_error": "An unexpected error has occurred",
        "failed_to_load_alert": "Failed to load vehicles page. Please try again later",
        "failed_to_update_telematics_unit": "Failed to update telematics unit. Please try again later",
        "failed_to_load_telematics_units": "Failed to load telematics units. Please try again later",
        "failed_to_create_telematics_unit": "Failed to create Telematics Unit, please check configuration and try again",
        "failed_to_delete_telematics_unit": "Failed to delete telematics unit. Please try again later!",
        "failed_to_set_distance_telematics_unit": "Failed to set the distance on the telematics unit. Please try again later!",
        "failed_to_reboot_telematics_unit": "Failed to reboot the telematics unit. Please try again later!",
        "update_profile_error": "An unexpected error occurred when updating profile",
        "delete_user_error": "Failed to delete user"
    },
    "components": {
        "pagination": {
            "pagination-text": "Showing page {0} of {1}",
            "total-results-text": "{results} Total Results"
        },
        "manage-vehicles": {
            "hide-deactivated": "Hide Deactivated",
            "show-deactivated": "Show Deactivated",
            "show-summary": "Show Summary",
            "show-map": "Show Map"
        },
        "telematics-unit-overview": {
            "unit-attached-to": "Unit attached to",
            "unit-not-connected": "This unit is not currently attached to a vehicle. Data received from this unit will not be processed."
        },
        "organization-integrations": {
            "integration": "Integration",
            "integrations": "Integrations",
            "integration-active": "Integration Active",
            "click-to-activate-integration": "Click to activate integration",
            "register-integration": "Register Integration"
        },
        "vehicle-map": {
            "last-known-position": "Last Known Position",
            "show-low-emission-zones": "Show low emission zones",
            "about-lez": "About LEZ",
            "penalty-charges": "Penalty charges",
            "show-service-vehicles": "Show service vehicles"
        },
        "vehicle-overview": {
            "download-vehicle-data": "Download Vehicle Data"
        },
        "vehicle-data": {
            "vehicle-data-explorer": "Vehicle Data Explorer"
        },
        "vehicle-overview-mot": {
            "mot-status": "MOT/ Recall Status"
        },
        "vehicle-overview-contact-itdn": {
            "contact-itdn": "Contact ITDN",
            "submit": "Submit ITDN Job",
            "cancel-prompt": "To cancel a job, please contact ITDN with your job reference number."
        },
        "vehicle-overview-create-job-sheet": {
            "create-job-sheet": "Create Job Sheet",
            "technician": "Technician",
            "location": "Location",
            "work-items": "Work Items",
            "work-items-subheading": "Enter a work item and click \"Add\""
        },
        "vehicle-overview-notification-config": {
            "manage-notifications": "Manage Notifications"
        },
        "vehicle-overview-events": {
            "event-log": "Event Log",
            "show-vehicle-events": "Show Vehicle Events",
            "display-celluar-trace": "Display cellular reported GPS trace",
            "job-sheet-number": "Job sheet number",
            "no-comments": "No comments were left with this job sheet"
        },
        "tyre-tread": {
            "tyre-tread": "Tyre tread",
            "submit-tread-data": "Submit tread data"
        },
        "edit-fleet-modal": {
            "default-maintenance": "Default maintenance threshold",
            "default-warning": "Default warning threshold",
            "default-danger": "Default danger threshold",
            "update-fleet": "Update Fleet",
            "subheading": "Update general fleet information"
        },
        "edit-fleet-move-organization": {
            "update-organization": "Update Organization",
            "subheading": "Move this fleet into another organization"
        },
        "fleet-base": {
            "kilometer_report": "Kilometer Report",
            "generic-error-message": "Something went wrong",
            "error-modal-title": "This fleet was not able to be deleted. Please check the following before retrying.",
            "error-hints": {
                "move-vehicles": "All vehicles have been moved to another fleet",
                "delete-depots": "All depots have been removed from the fleet",
                "delete-reports": "All scheduled reports for this fleet have been deleted"
            }
        },
        "vehicle-speed-pill": {
            "current-speed": "Current Speed {0} Km/h"
        },
        "vehicle-hours-pill": {
            "current-displayed-hours": "{0} operational hours"
        },
        "vehicle-voltage-pill": {
            "current-voltage": "{0}V"
        },
        "vehicle-download": {
            "selected-service-count": "{0} services selected"
        },
        "vehicle-distance-pill": {
            "current-distance": "Distance Travelled {0} Km"
        },
        "configurator-connection-status": {
            "unable-to-connect-heading": "Unable to connect to configurator client",
            "unable-to-connect-msg": "The configurator client was unable to establish a connection to the server, please check your internet connection and try again.",
            "establishing-connection-heading": "Establishing connection to configurator client",
            "establishing-connection-msg": "If this message persists, please check your internet and try again."
        },
        "configurator-pending-messages": {
            "no-pending-messages": "No Pending Messages...",
            "pending-message-deletion": "Pending messages will expire after 1 month of inactivity."
        },
        "tread-depth-wizard": {
            "title": "Tyre Tread Depth",
            "submit": "Submit tread data",
            "no-data-error": "At least one wheel and tread position must have a reading"
        },
        "permissions-modal": {
            "update-permissions": "Update permissions",
            "selected-permissions-count": "{0} permissions selected",
            "no-permissions": "This user has no permissions",
            "failed-to-update": "Failed to update permissions"
        },
        "organization-create-user": {
            "email-already-exists": "A user with that username or email already exists"
        },
        "tyre-modal": {
            "failed-to-remove-sensor": "Failed to remove sensor from vehicle. Please try again later",
            "no-tread-data": "At least one tread position requires a reading"
        }
    },
    "colours": {
        "black": "Black",
        "blue": "Blue",
        "green": "Green",
        "yellow": "Yellow",
        "orange": "Orange",
        "red": "Red"
    },
    "pages": {
        "manage_reports": "Manage Reports",
        "manage_sim": "Manage SIM Cards",
        "edit_sim": "Edit SIM Card"
    },
    "navbar": {
        "kit_menu": "Open kit menu",
        "user_menu": "Open user menu",
        "vehicles": "Vehicles",
        "fleets": "Fleet Reports",
        "sensors": "Sensors",
        "reports": "Scheduled Reporting",
        "kit": "Kit",
        "sim_cards": "Sim Cards",
        "telematics_boxes": "Telematics Boxes",
        "settings": "Account Settings",
        "logout": "Logout"
    },
    "landing": {
        "quick_actions": "Quick Actions",
        "add_vehicle": "Add Vehicle",
        "add_vehicle_prompt": "Add a vehicle to get started",
        "add_report": "Add Report",
        "add_report_prompt": "Setup a report to get updates about your fleets",
        "dashboard": "Dashboard",
        "sensors_on_vehicles": "Sensors on vehicles",
        "sensors_on_vehicles_prompt": "of sensors are assigned to a vehicle",
        "sensors_off_vehicles": "Sensors off vehicles",
        "sensors_off_vehicles_prompt": "of sensors are not assigned to a vehicle. These sensors will not be able to collect data",
        "vehicles_prompt": "vehicles in your organisation and all organisations you manage",
        "latest_changes": "Latest Changes"
    },
    "login": {
        "sign_in_message": "Sign in to your account",
        "sign_in": "Sign in",
        "incorrect_username_password": "Incorrect username or password"
    },
    "vehicle_attributes": {
        "co2_emissions": "CO2 Emissions(g/km)",
        "type_approval": "Type Approval",
        "euro_status": "Euro Status"
    },
    "manage_vehicles": {
        "registration": "Registration",
        "imei": "IMEI",
        "fleet": "Fleet",
        "depot": "Depot",
        "show_deactivated": "Show/hide deactivated vehicles",
        "show_archived": "Show/hide archived vehicles",
        "add_vehicle": "Add Vehicle",
        "total_vehicles": "Total Vehicles",
        "details": "Tyre Details",
        "no_imei": "No IMEI",
        "alert_level": "Alert Level",
        "open_map": "Open Map",
        "no_results": "No results found",
        "no_results_subheading": "No vehicles could be found matching your search criteria",
        "no_vehicles": "No Vehicles",
        "no_vehicles_subheading": "You currently have no vehicles in your organisation. Click \"Add Vehicle\" to create one",
        "sort_by": "Sort By",
        "tyre_maintenance": "Maintenance"
    },
    "edit_vehicle": {
        "vehicles": "Vehicles",
        "overview": "Overview",
        "logs": "Logs",
        "checklist": "Checklist",
        "mot_history": "MOT History"
    },
    "vehicle_overview": {
        "vehicle_alerts": "Email Alerts",
        "speed": "Speed",
        "engine_capacity": "Engine Capacity",
        "revenue_weight": "Revenue Weight",
        "vehicle_alerts_description": "Receive email alerts if this vehicle encounters an issue.",
        "environmental_breakdown": "environmetal breakdown",
        "euro_rating_title": "No EURO rating set",
        "euro_rating_subtitle": " Please set the vehicle's EURO rating in config to view this section.",
        "add_config_error": "Please give this vehicle a configuration to begin adding sensors",
        "archived": "Archived",
        "deactivated": "Deactivated",
        "unarchived": "Unarchived",
        "thresholds": "Pressure thresholds",
        "temperature_thresholds": "Temperature thresholds",
        "below_recommended": "below recommended",
        "sr_enter_edit_mode": "Enter edit mode for vehicle details",
        "sr_exit_edit_mode": "Exit edit mode for vehicle details",
        "type": "Type",
        "environmental": "Environmental",
        "manufacturer": "Manufacturer",
        "model": "Model",
        "chassis_type": "Chassis Type",
        "fuel_type": "Fuel Type",
        "engine_type": "Engine Type",
        "mot_due": "MOT Due",
        "fleet": "Fleet",
        "depot": "Depot",
        "imei": "IMEI",
        "configuration": "Configuration",
        "application": "Application",
        "emergency_contact": "Emergency Contact",
        "country": "Country",
        "torque": "Torque",
        "axle": "Axle",
        "sr_enter_edit_axle_mode": "Enter edit mode for axle",
        "sr_exit_edit_axle_mode": "Exit edit mode for axle",
        "rim_size": "Rim size",
        "tyre_size": "Tyre size",
        "minimum_weight": "Minimum weight",
        "maximum_weight": "Maximum weight",
        "recommended_pressure": "Recommended Pressure ({unit})",
        "recommended_temperature": "Recommended Temperature ({unit})",
        "warning_temperature_threshold": "Warning Temperature ({unit})",
        "maintenance_threshold": "Maintenance Threshold",
        "maintenance_threshold_description": "Threshold that will activate maintenance status",
        "warning_threshold": "Warning Threshold",
        "warning_threshold_description": "Threshold that will activate warning status",
        "critical_threshold": "Critical Threshold",
        "critical_threshold_description": "Threshold that will activate critical status",
        "maintenance_level": "Maintenance Level ({unit})",
        "warning_level": "Warning Level ({unit})",
        "danger_level": "Danger Level ({unit})",
        "submit": "Submit",
        "sensors": "Sensors",
        "assigned_to": "assigned to",
        "last_seen": "Last Seen",
        "no_data": "No data reported within the selected time frame",
        "no_data_of_type": "No {data_type} data reported within the selected time frame",
        "please_check": "Please check vehicle logs for more information",
        "temp_over_time": "Sensor Temperature/ Pressure over time",
        "current_pressure": "Current Pressure",
        "current_cold_pressure": "Current Cold Pressure",
        "current_temp": "Current Temperature",
        "time_to_critical": "Time to critical",
        "recommended_tyre_make": "Recommended Tyre Make",
        "recommended_tyre_pattern": "Recommended Tyre Pattern",
        "rim_type": "Rim Type",
        "tyre_condition": "Tyre Condition",
        "axle_type": "Axle Type",
        "new_tread_depth": "New Tread Depth",
        "gps_unavailable": "GPS unavailable",
        "gps_fix_unavailable": "No GPS Fix",
        "current_location": "Current location",
        "no_sensors": "There are no sensors currently attached to this vehicle",
        "kilometres_travelled": "Kilometres travelled",
        "kilometres_per_hour": "Kilometres per hour",
        "kilometers_hour": "kilometers/hour",
        "schematic_view": "Schematic View",
        "list_view": "List View",
        "battery_unavailable": "Battery Unavailable",
        "tyre_make": "Tyre Make",
        "tyre_pattern": "Tyre pattern",
        "estimated_co2": "Total estimated CO2 emissions (Kg)",
        "total_emissions": "Total Emissions",
        "deactivate_vehicle": "Deactivate Vehicle",
        "spannered_off": "Maintenance mode",
        "on": "on",
        "off": "off",
        "view_on_street_view": "View on street view",
        "satcom_serial_number": "Satcom Serial Number"
    },
    "attach_sensor_modal": {
        "checksum_calculator": "Checksum",
        "create_title": "Create a new sensor at position",
        "id": "ID",
        "type": "Type",
        "create_sensor": "Create and attach sensor",
        "attach_title": "Select a sensor to attach to",
        "no_unassigned_sensors": "No unassigned sensors found",
        "no_unassigned_subheading": "Remove a sensor from a vehicle or click the plus button to create a new one",
        "show_assigned": "Show assigned sensors",
        "attach_sensor": "Attach Sensor",
        "missing_sensor": "No Sensor",
        "create_new_sensor": "Create new sensor",
        "attach-error": "Failed to attach the selected sensor. Please try again later",
        "create-error": "Failed to create new sensor. Please try again later"
    },
    "delete_sensor_modal": {
        "delete_message": "This action is irreversable and will permanently delete this sensor from the platform. If you want to unlink the sensor for reuse, please cancel this operation and select the unlink button instead."
    },
    "vehicle_logs": {
        "limit": "Limit",
        "sort": "Sort",
        "newest": "Newest",
        "oldest": "Oldest",
        "filter": "Filter",
        "any": "Any",
        "decode_success": "Decode Success",
        "decode_error": "Decode Error",
        "configuration_error": "Configuration Error",
        "since": "Since",
        "now": "Now",
        "reason": "Reason",
        "sensor_id": "Sensor ID",
        "temperature": "Temperature",
        "pressure": "Pressure",
        "speed": "Speed",
        "distance": "Distance",
        "no_logs": "No Logs to display",
        "no_logs_subheading": "Please check vehicle configuration.",
        "tpm_success_message": "Successfully parsed TPM packet",
        "speed_dist_success_message": "Successfully parsed speed and distance packet",
        "sensor_data_error_message": "Error decoding packet",
        "lantronix_data_error_message": "Please check telematics unit config",
        "sensor_handshake_message": "Handshake successfully decoded",
        "sensor_error_message": "Something went wrong (undefined sensor error)",
        "unrecognized_external_sensor_pakcet": "Unrecognized external sensor packet received",
        "sensor_flag_set": "Sensor status flag set, sensor has not reported data in the last 60 minutes"
    },
    "vehicle_checklist": {
        "checklist": "Checklist",
        "issued_by": "Issued By",
        "created_at": "Created At",
        "checklists_not_supported": "Checklists are currently not supported for this vehicle type",
        "return_to_checklists": "Return to checklists",
        "return_to_previous_recordings": "Return to previous recordings",
        "add_checklist": "Add checklist",
        "reset": "Reset",
        "brakes": "Brakes",
        "horn_steering": "Horn/Steering",
        "horn_and_steering": "Horn/Steering",
        "lights": "Lights",
        "mirror_glass": "Mirror/Glass",
        "seat_belts": "Seat Belts",
        "washers_wipers": "Washers/Wipers",
        "battery": "Battery",
        "fluids_fuel_oil": "Fluids/Fuel/Oil",
        "bodywork_doors": "Bodywork/Doors",
        "bodywork_and_doors": "Bodywork/Doors",
        "exhaust": "Exhaust",
        "tyres_wheels": "Tyres/Wheels",
        "tyres_and_wheels": "Tyres/Wheels",
        "load": "Load",
        "towbar_taillift": "Towbar/Taillift",
        "towbar_or_tail_lift": "Towbar/Taillift",
        "van_check": "Van Check",
        "previous_recordings": "Previous Recordings",
        "faults": "Faults",
        "no_recordings": "There are no previous recordings for this vehicle",
        "mirrors_cameras_and_glass": "Mirrors Cameras and Glass",
        "mirrors_and_glass": "Mirrors Cameras and Glass",
        "windscreen_wipers_and_washers": "Windscreen Wipers and Washers",
        "washers_and_wipers": "Windscreen Wipers and Washers",
        "warning_lights": "Warning Lights",
        "steering_and_electronic_stability_control": "Steering and Electronic Stability Control",
        "horn": "Horn",
        "breaks_and_air_build": "Brakes and Air Build",
        "height_marker": "Height Marker",
        "seat_belts_and_cab_interior": "Seat Belts and Cab Interior",
        "security_and_condition_of_cab_doors_steps": "Security and Condition of Cab Doors Steps",
        "brake_lines": "Brake Lines",
        "electrical_connections": "Electrical Connections",
        "coupling_security": "Coupling Security",
        "load_security": "Load Security",
        "number_plate": "Number Plate",
        "reflectors_and_lights": "Reflectors and Lights",
        "markers": "Markers",
        "ancillary_equipment": "Ancillary Equipment",
        "lights_indicators_and_side_repeaters": "Lights Indicators and Side Repeaters",
        "fuel_oil_leaks": "Fuel Oil Leaks",
        "security_and_condition_of_body_wings": "Security and Condition of Body Wings",
        "battery_security_and_condition": "Battery Security and Condition",
        "diesel_exhaust_fluid_adblue": "Diesel Exhaust Fluid Adblue",
        "excessive_engine_exhaust_smoke": "Excessive Engine Exhaust Smoke",
        "aleternative_fuel_systems_and_isolation": "Alternative Fuel Systems and Isolation",
        "spray_suppression": "Spray Suppression",
        "tyres_and_wheel_fixing": "Tyres and Wheel Fixing",
        "high_voltage_emergency_cut_off_switch": "High Voltage Emergency Cut Off Switch",
        "cab_mirrors_seatbelts_glass": "Cab, Mirrors, Seatbealts, and Glass",
        "cab_mirror_seat_belt_and_glass": "Cab, Mirrors, Seatbealts, and Glass",
        "horn_and_gauges": "Horn and Gauges",
        "turn_signals": "Turn Signals",
        "backup_lights_and_alarm": "Backup Lights and Alarm",
        "fire_extinguisher_condition": "Fire Extinguisher Condition",
        "windshield_wipers_and_fluid": "Windshield Wipers and Fluid",
        "fuel_levels": "Fuel Levels",
        "engine_oil": "Engine Oil",
        "transmission_fluid": "Transmission Fluid",
        "brake_fluid": "Brake Fluid",
        "cooling_system_fluid": "Cooling System Fluid",
        "oil_leak_lube": "Oil Leak/Lube",
        "brake_condition": "Brake condition (dynamic service, park, etc.)",
        "tyres_or_tracks": "Tyres or tracks (inflation, damage, etc.)",
        "tires_or_tracks": "Tyres or tracks (inflation, damage, etc.)",
        "hydraulic_oil": "Hydraulic Oil",
        "hose_condition": "Hose Condition",
        "coupling_devices_and_connectors": "Coupling Devices and Connectors",
        "exhaust_system": "Exhaust System",
        "blade_bloom_ripper": "Blade/Bloom/Ripper Condition",
        "no_notes": "There were no notes provided with this recording",
        "licence_lamp": "Licence Lamp",
        "fift_wheel_grease": "Fifth Wheel Grease",
        "electrical_connections_and_terminals": "Electrical Connections and Terminals",
        "dot_reflective_markings": "Dot Reflective Markings",
        "access_ramps": "Access Ramps",
        "brake_lamps_and_reflectors": "Brake Lamps and Reflectors",
        "fasteners": "Fasteners",
        "ragged_sharp_edges_dammage_body_condition": "Ragged Sharp Edges/Body Condition",
        "trailer_sides_roof_damage": "Trailer Side Roof Damage",
        "latches_gates_opening_devices": "Latches Gates/Opening Devices",
        "safety_kits": "Safety Kits",
        "adr_kit": "ADR Kit",
        "fridge_unit": "Fridge Unit",
        "fridge_unit_fuels": "Fridge Unit Fuels",
        "straps": "Straps",
        "tailift_condition": "Taillift Condition",
        "tailift_operation": "Taillift Operation",
        "bumpers_guards": "Bumper Guards",
        "additional_lamps": "Additional Lamps",
        "truck_and_trailer_cables_brake_connections": "Cable Brake Connections",
        "air_suspension_leaks": "Air Suspension Leaks",
        "spare_wheel_and_carrier": "Spare Wheel and Carrier",
        "hooses_and_pipes": "Hoses and Pipes",
        "tyre_thread_depth": "Tyre Tread Depth",
        "tyre_condition": "Tyre Condition",
        "tachograph": "Tachograph",
        "adblue_level_ok": "Adblue level ok",
        "steering": "Steering",
        "fuel_filter_cap": "Fuel filler cap",
        "cameras": "Cameras",
        "load_secure_restraints": "Load secure restraints",
        "doors": "Doors",
        "loading_bar": "Loading bar",
        "three_tacho_rolls": "Three tacho rolls",
        "sack_truck": "Sack truck",
        "wipes": "Wipes",
        "first_aid_kit": "First aid kit",
        "fire_extinguisher": "Fire Extinguisher",
        "breakdown_booklet": "Breakdown booklet",
        "incident_form": "Incident form",
        "safety_check_sheet": "Safety check sheet",
        "van_dimensions": "Van dimensions",
        "no_valuables_on_sight": "No valuables on sight",
        "fuel_on_return": "Fuel on return",
        "fuel_reciept": "Fuel reciept",
        "wheel_nut_indicators": "Wheel nut indicators",
        "wheel_torque_settings": "Wheel torque settings",
        "attach_image": "Attach Image",
        "media": "Media",
        "failed-to-upload": "Failed to upload one or more images",
        "failed-creation": "Failed to create checklist"
    },
    "mot_history": {
        "completed_date": "Completed Date",
        "expiry_date": "Expiry Date",
        "odometer_value": "Odometer Value",
        "test_number": "Test Number",
        "no_vehicle_found_error": "No MOT tests could be found for this registration",
        "test_location": "Test Location",
        "major_defects": "Major Defects",
        "first-mot-due": "First MOT Due",
        "date-registered": "Date Registered"
    },
    "new_vehicle": {
        "add_vehicle": "Add Vehicle",
        "basic_information_title": "Basic Information",
        "basic_information_prompt": "Basic Information regarding the vehicle",
        "registration": "Registration",
        "check_dvla": "Check DVLA",
        "dvla_duplicate_vehicle": "A vehicle with this registration already exists",
        "manufacturer": "Manufacturer",
        "model": "Model",
        "depot": "Depot",
        "depots": "Depots",
        "fleets": "Fleets",
        "missing_sensor": "Without Sensor",
        "sensors": "Sensors",
        "engine_type": "Engine Type",
        "fuel_type": "Fuel Type",
        "map": "Map",
        "mot_due": "MOT Due",
        "chassis_type": "Chassis Type",
        "vehicle_type": "Vehicle Type",
        "imei": "IMEI",
        "application": "Application",
        "torque": "Torque",
        "sensor_information_title": "Sensor Information",
        "sensor_information_prompt": "Information regarding how many sensors, and which type",
        "configuration": "Configuration",
        "sensor_manifest": "Sensor Manifest",
        "create_without_sensors": "Create without sensors",
        "create_with_sensors": "Create with sensors",
        "list": "Vehicles",
        "vin": "VIN"
    },
    "vehicle_download": {
        "pressure": "Tyre pressure",
        "cold_pressure": "Tyre cold pressure",
        "temperature": " Tyre temperature",
        "select_all": "Select all",
        "deselect_all": "Deselect all",
        "filter_services": "Filter services",
        "subheading": "Specify the services and time frame within which you would like to download data from. Downloads may take a while, you can leave this page once the download has started and the file will appear in your downloads folder once completed."
    },
    "manage_fleets": {
        "add_fleet_contact": "Add contact to {fleet}",
        "contact_name": "Contact Name",
        "add_contact": "Add contact",
        "add_fleet": "Add Fleet",
        "total_fleets": "Total Fleets",
        "filter_fleets": "Search fleets",
        "defaults": "Defaults",
        "defaults_prompt": "These values will be used as the default thresholds for all new vehicles created in this fleet.",
        "create_depot_prompt": "Add a depot to the",
        "depots": "{ count } Depot | { count } Depots",
        "created_on": "Created on { date }",
        "add_depot": "Add Depot",
        "no_fleets": "No Fleets",
        "no_depots": "No Depots",
        "no_fleets_prompt": "You currently have no fleets in your organisation. Click \"Add Fleet\" to create one",
        "no_depots_prompt": "This fleet doesn't contain any depots. Click the add depot button to create one",
        "duplicate_fleet_error": "A fleet with that name already exists",
        "duplicate_depot_error": "A depot with that name already exists",
        "confirm_delete_depot_modal_text": "Are you sure you want to delete {depot}?",
        "confirm_delete_fleet_modal_text": "Are you sure you want to delete {fleet}?",
        "sensors_prompt": "The following vehicles are missing one or more sensors",
        "empty_prompt_header": "All vehicles correctly configured",
        "empty_prompt_subtitle": "There are no vehicles in this fleet with missing sensors",
        "subscribe": "Subscribe",
        "update_subscriptions": "Update",
        "unsubscribe": "Unsubscribe",
        "fleet_subscriptions": "Manage subscriptions",
        "subscribe_prompt": "Subscribe to all vehicles in this fleet",
        "unsubscribe_prompt": "Unsubscribe to all vehicles in this fleet",
        "colour": "Colour",
        "definition": "Definition",
        "black_definition": "No data",
        "blue_definition": "No data in the last 12 hours",
        "green_definition": "No issues",
        "yellow_definition": "Below maintenance threshold",
        "orange_definition": "Below warning threshold",
        "red_definition": "Below danger threshold",
        "subscribe_failed": "Failed to subscribe to this fleet",
        "subscribe_success": "Successfully subscribed to all vehicles in this fleet",
        "unsubscribe_failed": "Failed to unsubscribe from this fleet",
        "unsubscribe_success": "Successfully unsubscribed from all vehicles in this fleet",
        "delete_fleet": "Delete Fleet",
        "delete_failed": "Failed to delete this fleet",
        "update_depot": "Update depot",
        "show_map": "Show map",
        "hide_map": "Hide map",
        "edit_fleet": "Edit Fleet",
        "edit-depot": "Edit depot",
        "delete-depot": "Delete depot"
    },
    "sensors": {
        "add_sensor": "Add Sensor",
        "sensor_type": "Sensor Type",
        "bound": "Bound",
        "wheel_position": "Wheel Position",
        "on_vehicle": "On Vehicle",
        "not_on_vehicle": "Not On Vehicle",
        "no_vehicle": "No Vehicle",
        "updated_on": "Updated On",
        "no_wheel": "No Wheel",
        "same_id_error": "A sensor with that ID already exists",
        "no_reg_error": "Couldn't find a vehicle with that registration",
        "axle_number": "Axle Number",
        "wheel_number": "Wheel Number",
        "no_data_recorded": "No data recorded",
        "failed_to_load": "Failed to load sensor",
        "no_sensors": "No Sensors",
        "no_sensors_subheading": "You currently have no sensors in your organisation. Click \"Add Sensor\" to create one",
        "no_results_subheading": "No sensors could be found matching your search criteria",
        "tyre_size": "Tyre Size",
        "tyre_make": "Tyre Make",
        "tyre_pattern": "Tyre Pattern"
    },
    "reports": {
        "add_report": "Add Report",
        "reports": "reports",
        "add report": "add report",
        "schedule": "Schedule",
        "send_now": "Send Now",
        "edit_report": "Edit Report",
        "delete_report": "Delete Report",
        "emails": "Email(s)",
        "language": "Language",
        "ima": "Pre-Maintenance Inspection (PMI)",
        "km": "Kilometre (KM)",
        "co2": "Emissions (CO2)",
        "maintenance": "Maintenance",
        "vehicle_list": "Vehicle List",
        "condition": "Condition",
        "critical_maintenance": "Critical Maintenance",
        "basic_information": "Basic Information",
        "basic_information_prompt": "Select which type of report you would like to create and enter the required details",
        "frequency": "Frequency",
        "frequency_prompt": "Select how frequent you would like to send the report",
        "day": "Day",
        "daily": "Daily",
        "last-day-of-month": "Last day of month",
        "hour": "Hour",
        "failed_to_load": "Failed to load report. Please try again later",
        "error_sending": "an error occurred while sending report",
        "confirmation_send": "Report sent successfully",
        "delete_error": "an error occurred while deleting report",
        "no_reports": "No Reports",
        "no_reports_subheading": "You currently have no reports in your organisation. Click \"Add Report\" to create one",
        "month": "Month",
        "day-of-month": "Day of month",
        "every-month": "Every month",
        "weekly": "Weekly",
        "monthly": "Monthly"
    },
    "sim": {
        "add_sim": "Add SIM Card",
        "search_iccid": "Search ICCID",
        "iccid_prompt": "The unique identifier for the SIM Card",
        "msisdn_prompt": "The 10-14 digit phone number including the country code (e.g. 44 in UK)",
        "provider": "Provider",
        "send_sms": "Send SMS",
        "send_sms_prompt": "Send an SMS message to",
        "message": "Message",
        "sms_sent": "SMS successfully sent",
        "imsi_description": "Mobile Station ISDN number.",
        "provider_description": "Provider that issued the SIM card. If your SIM provider is not listed, please contact support.",
        "unable_to_delete": "Unable to delete SIM Card, please contact support.",
        "unable_to_edit": "Unable to edit SIM Card, please contact support.",
        "failed_to_create": "Failed to create SIM Card, please check configuration and try again",
        "no_sim_cards": "No SIM Cards",
        "no_sim_cards_subheading": "You currently have no SIM cards in your organisation. Click \"Add SIM Card\" to create one"
    },
    "telematics": {
        "edit_telematics": "Edit Telematics Unit",
        "no_update_information": "No update information",
        "add_telematics": "Add Telematics Box",
        "search_imei": "Search IMEI",
        "go-to-vehicle": "Go to vehicle",
        "software": "Software",
        "hardware": "Hardware",
        "main_port": "Main Port",
        "accessory_port": "Accessory Port",
        "device_name": "Device Name",
        "remove": "Remove Telematics Unit",
        "failed_to_load": "Failed to load telematics unit",
        "tyre-control-box-id": "Tyre Control Box ID",
        "no_telematics_boxes": "No Telematics Boxes",
        "process-from-configurator": "Process from Configurator",
        "no_telematics_boxes_subheading": "You currently have no telematics boxes in your organisation. Click \"Add Telematics Box\" to create one",
        "online": "Online",
        "offline": "Offline",
        "move-telematics-unit": "Move Telematics Unit",
        "overview": "Overview",
        "configurator": "Configurator",
        "pfal-command-reference": "PFAL Command Reference",
        "checksum-calculator": "Checksum Calculator",
        "reboot": "Are you sure you'd like to reboot the telematics box",
        "set-distance": "Set distance"
    },
    "settings": {
        "profile": "Profile",
        "old_password": "Old Password",
        "new_password": "New Password",
        "confirm_password": "Confirm Password",
        "update_password": "Update Password",
        "profile_prompt": "Basic information regarding your account.",
        "password_prompt": "Change your password.",
        "passwords_dont_match": "Passwords don't match",
        "first_name": "First Name",
        "last_name": "Last Name",
        "password": "Password",
        "locale": "Locale",
        "locale_prompt": "Change how information is displayed on the website. Language and temperature units selected here will also be reflected in reports e.g. IMA reports.",
        "language": "Language",
        "pressure_unit": "Pressure Unit",
        "temperature_unit": "Temperature Unit",
        "time_zone": "Time Zone",
        "coming_soon": "coming soon",
        "update_settings": "Update Settings",
        "test_sms": "Send a test SMS",
        "alerts": "Alerts",
        "no_alerts": "No Alerts",
        "alerts_subtitle": "You will receive alerts from these vehicles.",
        "organisation_subtitle": "Settings and information relating to your organisation.",
        "no_active_subscriptions_placeholder": "You have no active subscriptions. Subscriptions can be activated in the vehicle menu.",
        "add_user": "Add User",
        "username_error": "That username or email is already taken",
        "email_error": "Please enter a valid email address",
        "current_organisation": "Current (Default)",
        "confirm_delete_user_modal_text": "Are you sure you want to delete user {user}?",
        "updated-settings": "Updated settings",
        "failed-update": "Failed to update settings, please try again later",
        "failed-sms": "Failed to send SMS",
        "updated-username": "Updated username, you will be logged out, please log in again with your new credentials",
        "incorrect-password": "Incorrect password",
        "same-password": "Your new password cannot be the same as your old password"
    },
    "not_found_page": {
        "title": "Page not found",
        "subtitle": "Please check the URL in the address bar and try again.",
        "return_home": "Return Home"
    },
    "server_error_page": {
        "title": "Internal server error",
        "subtitle": "The server encountered an error and could not complete your request.",
        "return_home": "Return Home"
    },
    "invalid_resource": {
        "cannot_access_resource": "Cannot Access Resource",
        "subheading": "You either do not have the rights to access this page, or the resource does not exist.",
        "subheading_2": "Please contact a system administrator."
    },
    "application": {
        "logistics": "Logistics",
        "mixed_service": "Mixed Service",
        "waste": "Waste",
        "food_distribution": "Food Distribution",
        "food_waste": "Food Waste",
        "psv": "PSV",
        "off_highway": "Off Highway",
        "urban_last_mile": "Urban / Last Mile",
        "other": "Other"
    },
    "fuel": {
        "petrol": "PETROL",
        "diesel": "DIESEL",
        "electric": "ELECTRIC",
        "hybrid": "HYBRID",
        "biofuel": "BIOFUEL",
        "hydrogen_fuel_cell": "HYDROGEN FUEL CELL",
        "compressed_natural_gas": "COMPRESSED NATURAL GAS",
        "liquified_petroleum_gas": "LIQUIFIED PETROLEUM GAS",
        "non_applicable": "N/A"
    },
    "vehicle_type": {
        "bus": "Bus",
        "car": "Car",
        "crane": "Crane",
        "digger": "Digger",
        "dumper": "Dumper",
        "loader": "Loader",
        "tractor": "Tractor",
        "trailer": "Trailer",
        "truck": "Truck",
        "truck_trailer": "TruckTrailer",
        "tug": "Tug",
        "van": "Van",
        "service_vehicle": "Service Vehicle"
    },
    "tyre_condition": {
        "new": "NEW",
        "re-mould": "RE_MOULD",
        "re-grooved": "RE_GROOVED"
    },
    "axle_type": {
        "steer": "Steer",
        "drive": "Drive",
        "trailer": "Trailer"
    },
    "days": {
        "monday": "Monday",
        "tuesday": "Tuesday",
        "wednesday": "Wednesday",
        "thursday": "Thursday",
        "friday": "Friday",
        "saturday": "Saturday",
        "sunday": "Sunday"
    },
    "months": {
        "january": "January",
        "february": "February",
        "march": "March",
        "april": "April",
        "may": "May",
        "june": "June",
        "july": "July",
        "august": "August",
        "september": "September",
        "october": "October",
        "november": "November",
        "december": "December"
    },
    "dvla": {
        "no_registration": "Please enter a registration to check DVLA",
        "failed_to_create": "Failed to create vehicle",
        "not_found_error": "Vehicle not found",
        "no_vehicle_type": "Please select a vehicle type before checking registration with the DVLA"
    },
    "abbreviations": {
        "kilobytes": "KB"
    },
    "footer": {
        "powered_by_text": "Powered by RL Automotive"
    },
    "telematics-unit-vehicle-create-modal": {
        "imei-subtitle": "IMEI must contain only numbers",
        "no-sim-card": "No SIM Card",
        "create-sim-card-subtitle": "If you cannot find the SIM card listed below, you may need to create one",
        "no-matching-search": "No SIM Cards matching that search criteria",
        "search-results-hidden": "Further search results hidden"
    },
    "app-error-modal": {
        "extra-information": "Extra Information"
    }
}
