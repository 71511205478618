import type { ObjectValues } from "./types";

export const baseURL = import.meta.env.VITE_API_URL || "https://api.auto-trust.com";

export const asciiLowercase = "abcdefghijklmnopqrstuvwxyz" as const;
export const asciiUppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ" as const;

/** Enumeration of the "Alert Levels" mirroring the values provided by
 * the TyreWatch API.
 *
 * This is using a plain JS object instead of a TypeScript `enum`, a pattern
 * used throughout this codebase. See [here](https://www.youtube.com/watch?v=jjMbPt_H3RQ)
 * for further explanation on this pattern.
 */
export const AlertLevel = {
    CRITICAL: 4,
    WARNING: 3,
    MAINTENANCE: 2,
    OKAY: 1,
    STALE: 0,
} as const;

export const Service: Record<string, number> = {
    LATITUDE: 1,
    LONGITUDE: 2,
    ALTITUDE: 3,
    TIRES_ONE_A_PRESSURE: 4,
    TIRES_ONE_B_PRESSURE: 5,
    TIRES_ONE_C_PRESSURE: 6,
    TIRES_ONE_D_PRESSURE: 7,
    TIRES_TWO_A_PRESSURE: 8,
    TIRES_TWO_B_PRESSURE: 9,
    TIRES_TWO_C_PRESSURE: 10,
    TIRES_TWO_D_PRESSURE: 11,
    TIRES_THREE_A_PRESSURE: 12,
    TIRES_THREE_B_PRESSURE: 13,
    TIRES_THREE_C_PRESSURE: 14,
    TIRES_THREE_D_PRESSURE: 15,
    TIRES_FOUR_A_PRESSURE: 16,
    TIRES_FOUR_B_PRESSURE: 17,
    TIRES_FOUR_C_PRESSURE: 18,
    TIRES_FOUR_D_PRESSURE: 19,
    TIRES_FIVE_A_PRESSURE: 20,
    TIRES_FIVE_B_PRESSURE: 21,
    TIRES_FIVE_C_PRESSURE: 22,
    TIRES_FIVE_D_PRESSURE: 23,
    TIRES_SIX_A_PRESSURE: 24,
    TIRES_SIX_B_PRESSURE: 25,
    TIRES_SIX_C_PRESSURE: 26,
    TIRES_SIX_D_PRESSURE: 27,
    ODOMETER: 28,
    SPEED: 29,
    FUEL_LEVEL_ABSOLUTE: 30,
    FUEL_LEVEL_WARNING: 31,
    BRAKEPADS_WARNING: 32,
    ENGINE_COOLANT_LEVEL_WARNING: 33,
    ENGINE_COOLANT_TEMPERATURE_WARNING: 34,
    ENGINE_OIL_LEVEL_WARNING: 35,
    BATTERY_WARNING: 36,
    ENGINE_COOLANT_TEMPERATURE: 37,
    FUEL_LEVEL: 40,
    ADBLUE_LEVEL: 41,
    ENGINE_OIL_LEVEL: 42,
    DRIVING_RANGE_ADBLUE: 43,
    BATTERY_VOLTAGE: 44,
    SERVICE_INTERVAL_TIME: 45,
    SERVICE_INTERVAL_DISTANCE: 46,
    ENGINE_LIMP_MODE_IS_ACTIVE: 47,
    BRAKES_PARKBRAKE_IS_ENGAGED: 48,
    BRAKES_BRAKEFLUID_WARNING: 49,
    TANK_WASHWATER_LEVEL_WARNING: 50,
    BRAKES_BRAKEPADS_PREWARNING: 51,
    AUTOTRUST_CONFIDENCE_LEVEL: 52,
    TIRES_ONE_A_TEMPERATURE: 53,
    TIRES_ONE_B_TEMPERATURE: 54,
    TIRES_ONE_C_TEMPERATURE: 55,
    TIRES_ONE_D_TEMPERATURE: 56,
    TIRES_TWO_A_TEMPERATURE: 57,
    TIRES_TWO_B_TEMPERATURE: 58,
    TIRES_TWO_C_TEMPERATURE: 59,
    TIRES_TWO_D_TEMPERATURE: 60,
    TIRES_THREE_A_TEMPERATURE: 61,
    TIRES_THREE_B_TEMPERATURE: 62,
    TIRES_THREE_C_TEMPERATURE: 63,
    TIRES_THREE_D_TEMPERATURE: 64,
    TIRES_FOUR_A_TEMPERATURE: 65,
    TIRES_FOUR_B_TEMPERATURE: 66,
    TIRES_FOUR_C_TEMPERATURE: 67,
    TIRES_FOUR_D_TEMPERATURE: 68,
    TIRES_FIVE_A_TEMPERATURE: 69,
    TIRES_FIVE_B_TEMPERATURE: 70,
    TIRES_FIVE_C_TEMPERATURE: 71,
    TIRES_FIVE_D_TEMPERATURE: 72,
    TIRES_SIX_A_TEMPERATURE: 73,
    TIRES_SIX_B_TEMPERATURE: 74,
    TIRES_SIX_C_TEMPERATURE: 75,
    TIRES_SIX_D_TEMPERATURE: 76,
    TIRES_ONE_A_COLD_PRESSURE: 77,
    TIRES_ONE_B_COLD_PRESSURE: 78,
    TIRES_ONE_C_COLD_PRESSURE: 79,
    TIRES_ONE_D_COLD_PRESSURE: 80,
    TIRES_TWO_A_COLD_PRESSURE: 81,
    TIRES_TWO_B_COLD_PRESSURE: 82,
    TIRES_TWO_C_COLD_PRESSURE: 83,
    TIRES_TWO_D_COLD_PRESSURE: 84,
    TIRES_THREE_A_COLD_PRESSURE: 85,
    TIRES_THREE_B_COLD_PRESSURE: 86,
    TIRES_THREE_C_COLD_PRESSURE: 87,
    TIRES_THREE_D_COLD_PRESSURE: 88,
    TIRES_FOUR_A_COLD_PRESSURE: 89,
    TIRES_FOUR_B_COLD_PRESSURE: 90,
    TIRES_FOUR_C_COLD_PRESSURE: 91,
    TIRES_FOUR_D_COLD_PRESSURE: 92,
    TIRES_FIVE_A_COLD_PRESSURE: 93,
    TIRES_FIVE_B_COLD_PRESSURE: 94,
    TIRES_FIVE_C_COLD_PRESSURE: 95,
    TIRES_FIVE_D_COLD_PRESSURE: 96,
    TIRES_SIX_A_COLD_PRESSURE: 97,
    TIRES_SIX_B_COLD_PRESSURE: 98,
    TIRES_SIX_C_COLD_PRESSURE: 99,
    TIRES_SIX_D_COLD_PRESSURE: 100,
    AUTOTRUST_CYBER_ATTACK: 101,
    TIRES_ONE_A_TIME_TO_CRITICAL: 102,
    TIRES_ONE_B_TIME_TO_CRITICAL: 103,
    TIRES_ONE_C_TIME_TO_CRITICAL: 104,
    TIRES_ONE_D_TIME_TO_CRITICAL: 105,
    TIRES_TWO_A_TIME_TO_CRITICAL: 106,
    TIRES_TWO_B_TIME_TO_CRITICAL: 107,
    TIRES_TWO_C_TIME_TO_CRITICAL: 108,
    TIRES_TWO_D_TIME_TO_CRITICAL: 109,
    TIRES_THREE_A_TIME_TO_CRITICAL: 110,
    TIRES_THREE_B_TIME_TO_CRITICAL: 111,
    TIRES_THREE_C_TIME_TO_CRITICAL: 112,
    TIRES_THREE_D_TIME_TO_CRITICAL: 113,
    TIRES_FOUR_A_TIME_TO_CRITICAL: 114,
    TIRES_FOUR_B_TIME_TO_CRITICAL: 115,
    TIRES_FOUR_C_TIME_TO_CRITICAL: 116,
    TIRES_FOUR_D_TIME_TO_CRITICAL: 117,
    TIRES_FIVE_A_TIME_TO_CRITICAL: 118,
    TIRES_FIVE_B_TIME_TO_CRITICAL: 119,
    TIRES_FIVE_C_TIME_TO_CRITICAL: 120,
    TIRES_FIVE_D_TIME_TO_CRITICAL: 121,
    TIRES_SIX_A_TIME_TO_CRITICAL: 122,
    TIRES_SIX_B_TIME_TO_CRITICAL: 123,
    TIRES_SIX_C_TIME_TO_CRITICAL: 124,
    TIRES_SIX_D_TIME_TO_CRITICAL: 125,
    TIRES_ONE_A_TREAD_DEPTHS: 126,
    TIRES_ONE_B_TREAD_DEPTHS: 127,
    TIRES_ONE_C_TREAD_DEPTHS: 128,
    TIRES_ONE_D_TREAD_DEPTHS: 129,
    TIRES_TWO_A_TREAD_DEPTHS: 130,
    TIRES_TWO_B_TREAD_DEPTHS: 131,
    TIRES_TWO_C_TREAD_DEPTHS: 132,
    TIRES_TWO_D_TREAD_DEPTHS: 133,
    TIRES_THREE_A_TREAD_DEPTHS: 134,
    TIRES_THREE_B_TREAD_DEPTHS: 135,
    TIRES_THREE_C_TREAD_DEPTHS: 136,
    TIRES_THREE_D_TREAD_DEPTHS: 137,
    TIRES_FOUR_A_TREAD_DEPTHS: 138,
    TIRES_FOUR_B_TREAD_DEPTHS: 139,
    TIRES_FOUR_C_TREAD_DEPTHS: 140,
    TIRES_FOUR_D_TREAD_DEPTHS: 141,
    TIRES_FIVE_A_TREAD_DEPTHS: 142,
    TIRES_FIVE_B_TREAD_DEPTHS: 143,
    TIRES_FIVE_C_TREAD_DEPTHS: 144,
    TIRES_FIVE_D_TREAD_DEPTHS: 145,
    TIRES_SIX_A_TREAD_DEPTHS: 146,
    TIRES_SIX_B_TREAD_DEPTHS: 147,
    TIRES_SIX_C_TREAD_DEPTHS: 148,
    TIRES_SIX_D_TREAD_DEPTHS: 149,
    BRAKES_LINING_IS_OK: 150,
    AXLE_LOAD: 151,
    OPERATIONAL_HOURS: 153,
} as const;

export const Permissions = [
    "vehicle:list",
    "vehicle:read",
    "vehicle:maintenance_mode",
    "vehicle:create",
    "vehicle:update",
    "vehicle:delete",
    "vehicle:create_job_sheet",
    "fleet:list",
    "fleet:read",
    "fleet:create",
    "fleet:update",
    "fleet:delete",
    "fleet:reports",
    "telematics_unit:list",
    "telematics_unit:read",
    "telematics_unit:create",
    "telematics_unit:update",
    "telematics_unit:delete",
    "telematics_unit:configurator",
    "user:list",
    "user:create",
    "user:update",
    "user:read",
    "user:delete",
    "user:settings",
    "user:password",
    "user:update_permissions",
    "organization:list",
    "organization:read",
    "organization:create",
    "organization:update",
    "organization:delete",
    "sensor:list",
    "sensor:read",
    "sensor:create",
    "sensor:update",
    "sensor:delete",
    "sim_card:list",
    "sim_card:create",
    "sim_card:delete",
    "user",
    "admin",
    "feature:tyre_tread_depths",
];

export type serviceId = ObjectValues<typeof Service>;

export const fuelTypes = {
    PETROL: "fuel.petrol",
    DIESEL: "fuel.diesel",
    ELECTRIC: "fuel.electric",
    HYBRID: "fuel.hybrid",
    BIOFUEL: "fuel.biofuel",
    "HYDROGEN FUEL CELL": "fuel.hydrogen_fuel_cell",
    "COMPRESSED NATURAL GAS": "fuel.compressed_natural_gas",
    "LIQUIFIED PETROLEUM GAS": "fuel.liquified_petroleum_gas",
    "N/A": "fuel.non_applicable",
} as const;

export const vehicleTypes = {
    Bus: "vehicle_type.bus",
    Car: "vehicle_type.car",
    Crane: "vehicle_type.crane",
    Digger: "vehicle_type.digger",
    Dumper: "vehicle_type.dumper",
    Loader: "vehicle_type.loader",
    "Service Vehicle": "vehicle_type.service_vehicle",
    Tractor: "vehicle_type.tractor",
    Trailer: "vehicle_type.trailer",
    Truck: "vehicle_type.truck",
    TruckTrailer: "vehicle_type.truck_trailer",
    Tug: "vehicle_type.tug",
    Van: "vehicle_type.van",
};

export const vehicleApplications = {
    Logistics: "application.logistics",
    "Mixed Service": "application.mixed_service",
    Waste: "application.waste",
    "Food Distribution": "application.food_distribution",
    "Food Waste": "application.food_waste",
    PSV: "application.psv",
    "Off-Highway": "application.off_highway",
    "Urban / Last Mile": "application.urban_last_mile",
    Other: "application.other",
} as const;

export const config_options = [
    "2x2",
    "2x2x2",
    "2x2x2x2",
    "2x2x2x2x2",
    "2x2x2x2x2x2",
    "2x2x4",
    "2x2x4x2",
    "2x2x4x2x2x2",
    "2x2x4x4x2",
    "2x2x4x4x2x2",
    "2x2x4x4x4",
    "2x2x4x4x4x4",
    "2x2x4x4",
    "2x4",
    "2x4x2",
    "2x4x2x2",
    "2x4x2x2x2",
    "2x4x2x2x2x2",
    "2x4x4x4",
    "2x4x4x4x4",
    "2x4x4x4x4x4",
    "2x4x4",
    "2x4x4x2",
    "2x4x4x2x2",
    "2x4x4x2x2x2",
    "4x4",
    "4x4x4",
    "4x4x4x4",
    "4x4x4x4x4",
    "4x4x4x4x4x4",
    "2x4x2x2x4x2",
    "2x4x2x4",
] as const;

export const tyreConditions = {
    NEW: "tyre_condition.new",
    RE_MOULD: "tyre_condition.re-mould",
    RE_GROOVED: "tyre_condition.re-grooved",
} as const;

export const axleTypes = {
    1: "axle_type.steer",
    2: "axle_type.drive",
    3: "axle_type.trailer",
};

export const hours = [
    "00:00",
    "01:00",
    "02:00",
    "03:00",
    "04:00",
    "05:00",
    "06:00",
    "07:00",
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
    "23:00",
] as const;

export const weekdays = [
    "days.monday",
    "days.tuesday",
    "days.wednesday",
    "days.thursday",
    "days.friday",
    "days.saturday",
    "days.sunday",
] as const;

export const months = [
    "months.january",
    "months.february",
    "months.march",
    "months.april",
    "months.may",
    "months.june",
    "months.july",
    "months.august",
    "months.september",
    "months.october",
    "months.november",
    "months.december",
];

export const MonthEnum = {
    1: "january",
    2: "february",
    3: "march",
    4: "april",
    5: "may",
    6: "june",
    7: "july",
    8: "august",
    9: "september",
    10: "october",
    11: "november",
    12: "december",
};

export const languages = [
    {
        name: "English",
        code: "en_GB",
    },
    {
        name: "Français",
        code: "fr",
    },
    {
        name: "Deutsch",
        code: "de",
    },
    {
        name: "Italiano",
        code: "it",
    },
    {
        name: "Türkçe",
        code: "tr",
    },
    {
        name: "Português",
        code: "pt",
    },
] as const;

export const manufacturers = [
    "MAN",
    "Trailer",
    "Scania",
    "Titan",
    "Volvo",
    "Dennison",
    "Mercedes-Benz",
    "Wilcox",
    "DAF",
    "SDC",
    "Mercedes",
    "Renault",
    "Fruehauf",
    "Iveco",
    "Bell",
    "Dennis",
    "Fiat",
    "Ford",
    "Caetano",
    "Krone",
    "Volkswagen",
    "JCB",
    "BMI",
    "Komatsu",
    "Citroen",
    "Rothdean",
    "Knapen",
    "Faymonville",
    "Montracon",
    "STAS",
    "Other",
];

export const countryCodes = [
    "GB",
    "IT",
    "DE",
    "AF",
    "AX",
    "AL",
    "DZ",
    "AS",
    "AD",
    "AO",
    "AI",
    "AQ",
    "AG",
    "AR",
    "AM",
    "AW",
    "AU",
    "AT",
    "AZ",
    "BS",
    "BH",
    "BD",
    "BB",
    "BY",
    "BE",
    "BZ",
    "BJ",
    "BM",
    "BT",
    "BO",
    "BQ",
    "BA",
    "BW",
    "BV",
    "BR",
    "IO",
    "BN",
    "BG",
    "BF",
    "BI",
    "CV",
    "KH",
    "CM",
    "CA",
    "KY",
    "CF",
    "TD",
    "CL",
    "CN",
    "CX",
    "CC",
    "CO",
    "KM",
    "CD",
    "CG",
    "CK",
    "CR",
    "CI",
    "HR",
    "CU",
    "CW",
    "CY",
    "CZ",
    "DK",
    "DJ",
    "DM",
    "DO",
    "EC",
    "EG",
    "SV",
    "GQ",
    "ER",
    "EE",
    "SZ",
    "ET",
    "FK",
    "FO",
    "FJ",
    "FI",
    "FR",
    "GF",
    "PF",
    "TF",
    "GA",
    "GM",
    "GE",
    "GH",
    "GI",
    "GR",
    "GL",
    "GD",
    "GP",
    "GU",
    "GT",
    "GG",
    "GN",
    "GW",
    "GY",
    "HT",
    "HM",
    "VA",
    "HN",
    "HK",
    "HU",
    "IS",
    "IN",
    "ID",
    "IR",
    "IQ",
    "IE",
    "IM",
    "IL",
    "JM",
    "JP",
    "JE",
    "JO",
    "KZ",
    "KE",
    "KI",
    "KP",
    "KR",
    "KW",
    "KG",
    "LA",
    "LV",
    "LB",
    "LS",
    "LR",
    "LY",
    "LI",
    "LT",
    "LU",
    "MO",
    "MK",
    "MG",
    "MW",
    "MY",
    "MV",
    "ML",
    "MT",
    "MH",
    "MQ",
    "MR",
    "MU",
    "YT",
    "MX",
    "FM",
    "MD",
    "MC",
    "MN",
    "ME",
    "MS",
    "MA",
    "MZ",
    "MM",
    "NA",
    "NR",
    "NP",
    "NL",
    "NC",
    "NZ",
    "NI",
    "NE",
    "NG",
    "NU",
    "NF",
    "MP",
    "NO",
    "OM",
    "PK",
    "PW",
    "PS",
    "PA",
    "PG",
    "PY",
    "PE",
    "PH",
    "PN",
    "PL",
    "PT",
    "PR",
    "QA",
    "RE",
    "RO",
    "RU",
    "RW",
    "BL",
    "SH",
    "KN",
    "LC",
    "MF",
    "PM",
    "VC",
    "WS",
    "SM",
    "ST",
    "SA",
    "SN",
    "RS",
    "SC",
    "SL",
    "SG",
    "SX",
    "SK",
    "SI",
    "SB",
    "SO",
    "ZA",
    "GS",
    "SS",
    "ES",
    "LK",
    "SD",
    "SR",
    "SJ",
    "SE",
    "CH",
    "SY",
    "TW",
    "TJ",
    "TZ",
    "TH",
    "TL",
    "TG",
    "TK",
    "TO",
    "TT",
    "TN",
    "TR",
    "TM",
    "TC",
    "TV",
    "UG",
    "UA",
    "AE",
    "UM",
    "US",
    "UY",
    "UZ",
    "VU",
    "VE",
    "VN",
    "VG",
    "VI",
    "WF",
    "EH",
    "YE",
    "ZM",
    "ZW",
] as const;

export const Integrations = {
    ITDN: 1,
    VOLVO_TRUCK_INGEST: 2,
    SERVICE_VEHICLES: 3,
    BRANDING: 4,
} as const;

export const TreadPosition = {
    INNER: 0,
    MIDDLE: 1,
    OUTER: 2,
} as const;

export type TreadPositions = ObjectValues<typeof TreadPosition>;

export type IntegrationId = ObjectValues<typeof Integrations>;
