{
    "general": {
        "sim_card": "SIM Karte",
        "serial_number": "Seriennummer",
        "contacts": "Kontakte",
        "provider": "Anbieter",
        "date": "Datum",
        "reconnect": "Wieder verbinden",
        "sort_by": "Sortiere nach",
        "sensor_id": "Sensor-ID",
        "start": "Start",
        "last_time_seen": "Zuletzt gesehen",
        "last_time_seen_desc": "Zuletzt gesehen (absteigend)",
        "include_inactive": "Inaktiv einschließen",
        "end": "Ende",
        "home": "Heim",
        "events": "Veranstaltungen",
        "changelog": "Änderungsprotokoll",
        "no_data": "Keine Daten empfangen",
        "notes": "Anmerkungen",
        "update": "Aktualisieren",
        "none": "Keiner",
        "username": "Nutzername",
        "password": "Passwort",
        "fleet": "Flotte",
        "fleet_name": "Flottenname",
        "registration": "Anmeldung",
        "vehicles": "Fahrzeuge",
        "settings": "Einstellungen",
        "cancel": "Stornieren",
        "id": "AUSWEIS",
        "close": "Schließen",
        "select_one": "Bitte wählen Sie eine aus",
        "email": "Email",
        "celcius": "Celsius",
        "farenheit": "Fahrenheit",
        "search": "Suchen",
        "confirm_delete": "Möchten Sie {value} wirklich löschen?",
        "name": "Name",
        "any": "Beliebig",
        "delete": "Löschen",
        "language": "Sprache",
        "type": "Typ",
        "yes": "Ja",
        "no": "NEIN",
        "archived": "Archiviert",
        "pressure": "Druck",
        "cold_pressure": "Kalter Druck"
    },
    "pages": {
        "manage_reports": "Berichte verwalten",
        "manage_sim": "SIM-Karten verwalten",
        "edit_sim": "SIM-Karte bearbeiten"
    },
    "navbar": {
        "kit_menu": "Kit-Menü öffnen",
        "user_menu": "Benutzermenü öffnen",
        "vehicles": "Fahrzeuge",
        "fleets": "Flotten",
        "sensors": "Sensoren",
        "reports": "Berichte",
        "kit": "Bausatz",
        "sim_cards": "Sim-Karten",
        "telematics_boxes": "Telematik-Boxen",
        "settings": "Einstellungen",
        "logout": "Ausloggen"
    },
    "landing": {
        "quick_actions": "Schnelle Aktionen",
        "add_vehicle": "Fahrzeug hinzufügen",
        "add_vehicle_prompt": "Fügen Sie ein Fahrzeug hinzu, um loszulegen",
        "add_report": "Bericht hinzufügen",
        "add_report_prompt": "Richten Sie einen Bericht ein um Updates zu Ihren Flotten zu erhalten",
        "dashboard": "Armaturenbrett",
        "sensors_on_vehicles": "Sensoren an Fahrzeugen",
        "sensors_on_vehicles_prompt": "von Sensoren sind einem Fahrzeug zugeordnet",
        "sensors_off_vehicles": "Sensoren aus Fahrzeugen",
        "sensors_off_vehicles_prompt": "der Sensoren sind keinem Fahrzeug zugeordnet. Diese Sensoren können keine Daten sammeln",
        "vehicles_prompt": "Fahrzeuge in Ihrer Organisation und allen Organisationen die Sie verwalten",
        "latest_changes": "Letzte Änderungen"
    },
    "login": {
        "sign_in_message": "Melden Sie sich bei Ihrem Konto an",
        "sign_in": "anmelden",
        "incorrect_username_password": "Benutzername oder Passwort falsch"
    },
    "manage_vehicles": {
        "registration": "Anmeldung",
        "imei": "IMEI",
        "fleet": "Flotte",
        "depot": "Depot",
        "show_archived": "Archivierte Fahrzeuge ein-/ausblenden",
        "show_deactivated": "Deaktivierte Fahrzeuge ein-/ausblenden",
        "add_vehicle": "Fahrzeug hinzufügen",
        "total_vehicles": "Fahrzeuge insgesamt",
        "details": "Einzelheiten",
        "no_imei": "Keine IMEI"
    },
    "edit_vehicle": {
        "vehicles": "Fahrzeuge",
        "overview": "Überblick",
        "logs": "Protokolle"
    },
    "vehicle_overview": {
        "add_config_error": "Bitte geben Sie diesem Fahrzeug eine Konfiguration um mit dem Hinzufügen von Sensoren zu beginnen",
        "archived": "Archiviert",
        "deactivated": "Deaktiviert",
        "thresholds": "Schwellen",
        "below_recommended": "unten empfohlen",
        "sr_enter_edit_mode": "Rufen Sie den Bearbeitungsmodus für Fahrzeugdetails auf",
        "sr_exit_edit_mode": "Bearbeitungsmodus für Fahrzeugdetails verlassen",
        "type": "Typ",
        "manufacturer": "Hersteller",
        "model": "Modell",
        "chassis_type": "Gehäusetyp",
        "fuel_type": "Treibstoffart",
        "engine_type": "Motortyp",
        "mot_expiry": "TÜV Ablauf",
        "fleet": "Flotte",
        "depot": "Depot",
        "imei": "IMEI",
        "configuration": "Aufbau",
        "application": "Anwendung",
        "emergency_contact": "Notfallkontakt",
        "country": "Land",
        "torque": "Drehmoment",
        "axle": "Achse",
        "sr_enter_edit_axle_mode": "Wechseln Sie in den Bearbeitungsmodus für die Achse",
        "sr_exit_edit_axle_mode": "Bearbeitungsmodus für Achse verlassen",
        "rim_size": "Felgengröße",
        "tyre_size": "Reifengröße",
        "minimum_weight": "Minimales Gewicht",
        "maximum_weight": "Maximales Gewicht",
        "recommended_pressure": "Empfohlener Druck",
        "recommended_temperature": "Empfohlene Temperatur",
        "maintenance_threshold": "Wartungsschwelle",
        "maintenance_threshold_description": "Schwellenwert der den Wartungsstatus aktiviert",
        "warning_threshold": "Warnschwelle",
        "warning_threshold_description": "Schwellenwert der den Warnstatus aktiviert",
        "critical_threshold": "Kritische Schwelle",
        "critical_threshold_description": "Schwellenwert der den kritischen Status aktiviert",
        "submit": "Einreichen",
        "sensors": "Sensoren",
        "assigned_to": "Zugewiesen an",
        "last_seen": "Zuletzt gesehen",
        "no_data": "Keine Daten in den letzten 12 Stunden gemeldet",
        "please_check": "Weitere Informationen finden Sie in den Fahrzeugprotokollen",
        "temp_over_time": "Sensortemperatur/-druck im Zeitverlauf",
        "current_pressure": "Aktueller Druck",
        "current_temp": "Aktuelle Temperatur",
        "tyre_make": "Reifen machen",
        "tyre_pattern": "Reifenmuster",
        "rim_type": "Felgentyp",
        "tyre_condition": "Reifenzustand",
        "new_tread_depth": "Neue Profiltiefe",
        "gps_unavailable": "GPS nicht verfügbar",
        "current_location": "Aktueller Standort",
        "no_sensors": "An diesem Fahrzeug sind derzeit keine Sensoren angebracht",
        "kilometres_travelled": "Kilometer gefahren",
        "kilometres_per_hour": "Kilometer pro Stunde",
        "schematic_view": "Schematische Ansicht",
        "list_view": "Listenansicht",
        "battery_unavailable": "Batterie nicht verfügbar"
    },
    "attach_sensor_modal": {
        "create_title": "Erstellen Sie einen neuen Sensor an Position",
        "id": "AUSWEIS",
        "type": "Typ",
        "create_sensor": "Sensor erstellen und anbringen",
        "attach_title": "Wählen Sie einen Sensor zum Anbringen aus",
        "no_unassigned_sensors": "Keine nicht zugewiesenen Sensoren gefunden",
        "no_unassigned_subheading": "Entfernen Sie einen Sensor aus einem Fahrzeug oder klicken Sie auf die Plus-Schaltfläche, um einen neuen zu erstellen",
        "show_assigned": "Zugeordnete Sensoren anzeigen",
        "attach_sensor": "Sensor anbringen",
        "missing_sensor": "Kein Sensor"
    },
    "delete_sensor_modal": {
        "delete_message": "Diese Aktion ist unumkehrbar und löscht diesen Sensor dauerhaft von der Plattform. Wenn Sie die Verbindung des Sensors zur Wiederverwendung aufheben möchten, brechen Sie diesen Vorgang bitte ab und wählen Sie stattdessen die Schaltfläche zum Aufheben der Verknüpfung."
    },
    "vehicle_logs": {
        "limit": "Grenze",
        "sort": "Sortieren",
        "newest": "Neueste",
        "oldest": "Älteste",
        "filter": "Filter",
        "any": "Beliebig",
        "decode_success": "Erfolg entschlüsseln",
        "decode_error": "Dekodierungsfehler",
        "configuration_error": "Konfigurationsfehler",
        "since": "Seit",
        "now": "Jetzt",
        "reason": "Grund",
        "sensor_id": "Sensor-ID",
        "temperature": "Temperatur",
        "pressure": "Druck",
        "speed": "Geschwindigkeit",
        "distance": "Distanz",
        "no_logs": "Keine Protokolle zum Anzeigen"
    },
    "new_vehicle": {
        "add_vehicle": "Fahrzeug hinzufügen",
        "basic_information_title": "Grundinformation",
        "basic_information_prompt": "Grundlegende Informationen zum Fahrzeug",
        "registration": "Anmeldung",
        "check_dvla": "Überprüfen Sie DVLA",
        "dvla_duplicate_vehicle": "Ein Fahrzeug mit diesem Kennzeichen existiert bereits",
        "manufacturer": "Hersteller",
        "model": "Modell",
        "depot": "Depot",
        "engine_type": "Motortyp",
        "fuel_type": "Treibstoffart",
        "mot_expiry": "TÜV Ablauf",
        "chassis_type": "Gehäusetyp",
        "vehicle_type": "Fahrzeugtyp",
        "imei": "IMEI",
        "application": "Anwendung",
        "torque": "Drehmoment",
        "sensor_information_title": "Sensorinformationen",
        "sensor_information_prompt": "Informationen darüber, wie viele Sensoren und welchen Typs",
        "configuration": "Aufbau",
        "sensor_manifest": "Sensormanifest",
        "create_without_sensors": "Erstellen ohne Sensoren",
        "create_with_sensors": "Erstellen Sie mit Sensoren"
    },
    "manage_fleets": {
        "add_fleet_contact": "Kontakt zu {fleet} hinzufügen",
        "contact_name": "Kontaktname",
        "add_contact": "Kontakt hinzufügen",
        "add_fleet": "Flotte hinzufügen",
        "total_fleets": "Gesamtflotten",
        "defaults": "Standardwerte",
        "defaults_prompt": "Diese Werte werden als Standardschwellenwerte für alle neuen Fahrzeuge verwendet, die in dieser Flotte erstellt werden.",
        "create_depot_prompt": "Fügen Sie ein Depot hinzu",
        "depots": "Depots",
        "add_depot": "Depot hinzufügen"
    },
    "sensors": {
        "add_sensor": "Sensor hinzufügen",
        "bound": "Gebunden",
        "position": "Position",
        "on_vehicle": "Auf Fahrzeug",
        "not_on_vehicle": "Nicht am Fahrzeug",
        "no_vehicle": "Kein Fahrzeug",
        "updated_at": "Aktualisiert am",
        "no_wheel": "Kein Rad",
        "same_id_error": "Ein Sensor mit dieser ID existiert bereits",
        "no_reg_error": "Konnte kein Fahrzeug mit diesem Kennzeichen finden",
        "axle_number": "Achsnummer",
        "wheel_number": "Radnummer",
        "no_data_recorded": "Keine Daten aufgezeichnet"
    },
    "reports": {
        "add_report": "Bericht hinzufügen",
        "schedule": "Zeitplan",
        "send_now": "Schick jetzt",
        "edit_report": "Bericht bearbeiten",
        "emails": "E-Mail(s)",
        "language": "Sprache",
        "ima": "Inspektion vor der Wartung (PMI)",
        "km": "Kilometer (KM)",
        "vehicle_list": "Fahrzeugliste",
        "basic_information": "Grundinformation",
        "basic_information_prompt": "Wählen Sie aus, welche Art von Bericht Sie erstellen möchten, und geben Sie die erforderlichen Details ein",
        "frequency": "Frequenz",
        "frequency_prompt": "Wählen Sie aus, wie oft Sie den Bericht senden möchten",
        "day": "Tag",
        "daily": "Täglich",
        "hour": "Stunde"
    },
    "sim": {
        "add_sim": "SIM-Karte hinzufügen",
        "search_iccid": "ICCID suchen",
        "iccid_prompt": "Die eindeutige Kennung für die SIM-Karte",
        "msisdn_prompt": "Die 10- bis 14-stellige Telefonnummer einschließlich der Landesvorwahl (z. B. 44 in Großbritannien)",
        "provider": "Anbieter",
        "send_sms": "SMS senden",
        "send_sms_prompt": "Senden Sie eine SMS-Nachricht an",
        "message": "Nachricht",
        "sms_sent": "SMS erfolgreich versendet",
        "imsi_description": "ISDN-Nummer der Mobilstation.",
        "provider_description": "Provider der die SIM-Karte ausgegeben hat. Wenn Ihr SIM-Anbieter nicht aufgeführt ist, wenden Sie sich bitte an den Support."
    },
    "telematics": {
        "edit_telematics": "Telematikeinheit bearbeiten",
        "no_update_information": "Keine Update-Informationen",
        "add_telematics": "Telematikbox hinzufügen",
        "search_imei": "IMEI suchen",
        "software": "Software",
        "hardware": "Hardware",
        "main_port": "Haupthafen",
        "accessory_port": "Zubehöranschluss",
        "device_name": "Gerätename",
        "remove": "Telematikeinheit ausbauen"
    },
    "settings": {
        "profile": "Profil",
        "profile_prompt": "Grundlegende Informationen zu Ihrem Konto. Um Ihr Passwort zu ändern, geben Sie Ihr neues Passwort in das New password",
        "first_name": "Vorname",
        "last_name": "Familienname Nachname",
        "new_password": "Neues Kennwort",
        "locale": "Gebietsschema",
        "locale_prompt": "Ändern Sie, wie Informationen auf der Website angezeigt werden. Die hier ausgewählten Sprach- und Temperatureinheiten werden auch in Berichten wiedergegeben, z. IMA-Berichte.",
        "language": "Sprache",
        "pressure_unit": "Druckeinheit",
        "temperature_unit": "Temperatureinheit",
        "time_zone": "Zeitzone",
        "coming_soon": "demnächst",
        "update_settings": "Update Einstellungen",
        "test_sms": "Senden Sie eine Test-SMS"
    },
    "not_found_page": {
        "title": "Seite nicht gefunden",
        "subtitle": "Bitte überprüfen Sie die URL in der Adressleiste und versuchen Sie es erneut."
    }
}
