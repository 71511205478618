{
    "general": {
        "sim_card": "Carte SIM",
        "serial_number": "Numéro de série",
        "contacts": "Contacts",
        "provider": "Fournisseur",
        "date": "Date",
        "reconnect": "Reconectar",
        "sort_by": "Trier par",
        "sensor_id": "Identifiant du capteur",
        "start": "Commencer",
        "last_time_seen": "Dernière fois vu",
        "last_time_seen_desc": "Dernière fois vu",
        "include_inactive": "Inclure les inactifs",
        "end": "Fin",
        "home": "Maison",
        "events": "Événements",
        "changelog": "Journal des modifications",
        "no_data": "aucune donnée recue",
        "notes": "Remarques",
        "update": "Mise à jour",
        "none": "Aucun",
        "username": "Nom d'utilisateur",
        "password": "Mot de passe",
        "fleet": "Flotte",
        "fleet_name": "Nom de la flotte",
        "registration": "inscription",
        "vehicles": "véhicules",
        "settings": "paramètres",
        "cancel": "Annuler",
        "id": "IDENTIFIANT",
        "close": "Fermer",
        "select_one": "S'il vous plait sélectionner en un",
        "email": "E-mail",
        "celcius": "Celsius",
        "farenheit": "Farenheit",
        "search": "Recherche",
        "confirm_delete": "Voulez-vous vraiment supprimer:",
        "name": "Nom",
        "any": "N'importe quel",
        "delete": "Supprimer",
        "language": "Langue",
        "type": "Taper",
        "yes": "Oui",
        "no": "Non",
        "archived": "Archivé",
        "pressure": "Pression",
        "cold_pressure": "Pression à froid"
    },
    "pages": {
        "manage_reports": "Gérer les rapports",
        "manage_sim": "Gérer les cartes SIM",
        "edit_sim": "Modifier la carte SIM"
    },
    "navbar": {
        "kit_menu": "le menu des kits",
        "user_menu": "le menu utilisateur",
        "vehicles": "Véhicules",
        "fleets": "Flottes",
        "sensors": "Capteurs",
        "reports": "Rapports",
        "kit": "Trousse",
        "sim_cards": "Cartes SIM",
        "telematics_boxes": "télématiques",
        "settings": "Paramètres",
        "logout": "Se déconnecter"
    },
    "landing": {
        "quick_actions": "Actions rapides",
        "add_vehicle": "Ajouter un véhicule",
        "add_vehicle_prompt": "Ajoutez un véhicule pour commencer",
        "add_report": "Ajouter un rapport",
        "add_report_prompt": "Configurez un rapport pour obtenir des mises à jour sur vos flottes",
        "dashboard": "Tableau de bord",
        "sensors_on_vehicles": "Capteurs sur les véhicules",
        "sensors_on_vehicles_prompt": "Capteurs sur les véhicules",
        "sensors_off_vehicles": "Capteurs hors véhicules",
        "sensors_off_vehicles_prompt": "Capteurs hors véhicules",
        "vehicles_prompt": "véhicules",
        "latest_changes": "Dernières modifications"
    },
    "login": {
        "sign_in_message": "Connectez-vous à votre compte",
        "sign_in": "S'identifier",
        "incorrect_username_password": "identifiant ou mot de passe incorrect"
    },
    "manage_vehicles": {
        "registration": "Inscription",
        "imei": "IMEI",
        "fleet": "Flotte",
        "depot": "Dépôt",
        "show_deactivated": "Afficher/masquer les véhicules désactivés",
        "show_archived": "Afficher/masquer les véhicules archivés",
        "add_vehicle": "Ajouter un véhicule",
        "total_vehicles": "Véhicules totaux",
        "details": "Détails",
        "no_imei": "Pas d'IMEI"
    },
    "edit_vehicle": {
        "vehicles": "Véhicules",
        "overview": "Aperçu",
        "logs": "Journaux"
    },
    "vehicle_overview": {
        "add_config_error": "Veuillez donner à ce véhicule une configuration pour commencer à ajouter des capteurs",
        "archived": "Archivé",
        "deactivated": "Désactivé",
        "thresholds": "seuils",
        "below_recommended": "ci-dessous recommandé",
        "sr_enter_edit_mode": "Entrer en mode édition pour les détails du véhicule",
        "sr_exit_edit_mode": "Quitter le mode d'édition pour les détails du véhicule",
        "type": "Taper",
        "manufacturer": "Fabricant",
        "model": "Modèle",
        "chassis_type": "Type de châssis",
        "fuel_type": "Type de carburant",
        "engine_type": "Type de moteur",
        "mot_expiry": "Expiration du CT",
        "fleet": "Flotte",
        "depot": "Dépôt",
        "imei": "IMEI",
        "configuration": "Configuration",
        "application": "Application",
        "emergency_contact": "Personne à contacter en cas d'urgence",
        "country": "Pays",
        "torque": "Couple",
        "axle": "Essieu",
        "sr_enter_edit_axle_mode": "Entrer en mode d'édition pour l'essieu",
        "sr_exit_edit_axle_mode": "Quitter le mode d'édition pour l'essieu",
        "rim_size": "Taille de jante",
        "tyre_size": "Taille des pneus",
        "minimum_weight": "Poids minimum",
        "maximum_weight": "Poids maximal",
        "recommended_pressure": "Pression recommandée",
        "recommended_temperature": "Température recommandée",
        "maintenance_threshold": "Seuil d'entretien",
        "maintenance_threshold_description": "Seuil qui activera l'état de maintenance",
        "warning_threshold": "Seuil d'avertissement",
        "warning_threshold_description": "Seuil qui activera l'état d'avertissement",
        "critical_threshold": "Seuil critique",
        "critical_threshold_description": "Seuil critique",
        "submit": "Soumettre",
        "sensors": "Capteurs",
        "assigned_to": "assigné à",
        "last_seen": "Vu pour la dernière fois",
        "no_data": "Aucune donnée signalée au cours des 12 dernières heures",
        "please_check": "Veuillez consulter les journaux des véhicules pour plus d'informations",
        "temp_over_time": "Température/pression du capteur dans le temps",
        "current_pressure": "Pression actuelle",
        "current_temp": "Température actuelle",
        "tyre_make": "Marque de pneu",
        "tyre_pattern": "Modèle de pneu",
        "rim_type": "Type de jante",
        "tyre_condition": "État des pneus",
        "new_tread_depth": "Nouvelle profondeur de bande de roulement",
        "gps_unavailable": "GPS indisponible",
        "current_location": "Localisation actuelle",
        "no_sensors": "Aucun capteur n'est actuellement connecté à ce véhicule",
        "kilometres_travelled": "Kilomètres parcourus",
        "kilometres_per_hour": "Kilomètres par heure",
        "schematic_view": "Vue schématique",
        "list_view": "Affichage de liste",
        "battery_unavailable": "Batterie indisponible"
    },
    "attach_sensor_modal": {
        "create_title": "Créer un nouveau capteur à la position",
        "id": "IDENTIFIANT",
        "type": "Taper",
        "create_sensor": "Créer et attacher un capteur",
        "attach_title": "Sélectionnez un capteur à attacher à",
        "no_unassigned_sensors": "Aucun capteur non attribué trouvé",
        "no_unassigned_subheading": "Supprimez un capteur d'un véhicule ou cliquez sur le bouton plus pour en créer un nouveau",
        "show_assigned": "Afficher les capteurs affectés",
        "attach_sensor": "Attacher le capteur",
        "missing_sensor": "Pas de capteur"
    },
    "delete_sensor_modal": {
        "delete_message": "Cette action est irréversible et supprimera définitivement ce capteur de la plateforme. Si vous souhaitez dissocier le capteur pour le réutiliser veuillez annuler cette opération et sélectionner le bouton dissocier à la place."
    },
    "vehicle_logs": {
        "limit": "Limite",
        "sort": "Trier",
        "newest": "Le plus récent",
        "oldest": "Le plus ancien",
        "filter": "Filtre",
        "any": "N'importe quel",
        "decode_success": "Décoder le succès",
        "decode_error": "Erreur de décodage",
        "configuration_error": "Erreur de configuration",
        "since": "Depuis",
        "now": "Maintenant",
        "reason": "Raison",
        "sensor_id": "Identifiant du capteur",
        "temperature": "Température",
        "pressure": "Pression",
        "speed": "Vitesse",
        "distance": "Distance",
        "no_logs": "Aucun journal à afficher"
    },
    "new_vehicle": {
        "add_vehicle": "Ajouter un véhicule",
        "basic_information_title": "Informations de base",
        "basic_information_prompt": "Informations de base concernant le véhicule",
        "registration": "Inscription",
        "check_dvla": "Vérifier DVLA",
        "dvla_duplicate_vehicle": "Un véhicule avec cette immatriculation existe déjà",
        "manufacturer": "Fabricant",
        "model": "Modèle",
        "depot": "Dépôt",
        "engine_type": "Type de moteur",
        "fuel_type": "Type de carburant",
        "mot_expiry": "Expiration du CT",
        "chassis_type": "Type de châssis",
        "vehicle_type": "Type de véhicule",
        "imei": "IMEI",
        "application": "Application",
        "torque": "Couple",
        "sensor_information_title": "Informations sur le capteur",
        "sensor_information_prompt": "Informations concernant le nombre de capteurs et le type",
        "configuration": "Configuration",
        "sensor_manifest": "Manifeste du capteur",
        "create_without_sensors": "Créer sans capteurs",
        "create_with_sensors": "Créer avec des capteurs"
    },
    "manage_fleets": {
        "add_fleet_contact": "Ajouter un contact à {flotte}",
        "contact_name": "Nom du contact",
        "add_contact": "Ajouter le contact",
        "add_fleet": "Ajouter une flotte",
        "total_fleets": "Flottes totales",
        "defaults": "Valeurs par défaut",
        "defaults_prompt": "Ces valeurs seront utilisées comme seuils par défaut pour tous les nouveaux véhicules créés dans cette flotte.",
        "create_depot_prompt": "Ajouter un dépôt au",
        "depots": "Dépôts",
        "add_depot": "Ajouter un dépôt"
    },
    "sensors": {
        "add_sensor": "Ajouter un capteur",
        "bound": "Bondir",
        "position": "Position",
        "on_vehicle": "Sur véhicule",
        "not_on_vehicle": "Pas sur le véhicule",
        "no_vehicle": "Aucun véhicule",
        "updated_at": "Mis à jour à",
        "no_wheel": "Pas de roue",
        "same_id_error": "Un capteur avec cet ID existe déjà",
        "no_reg_error": "Impossible de trouver un véhicule avec cette immatriculation",
        "axle_number": "Numéro d'essieu",
        "wheel_number": "Numéro de roue",
        "no_data_recorded": "Aucune donnée enregistrée"
    },
    "reports": {
        "add_report": "Ajouter un rapport",
        "schedule": "Calendrier",
        "send_now": "Envoyer maintenant",
        "edit_report": "Modifier le rapport",
        "emails": "E-mail(s)",
        "language": "Langue",
        "ima": "Inspection prémaintenance (PMI)",
        "km": "Kilomètre (KM)",
        "vehicle_list": "Liste des véhicules",
        "basic_information": "Informations de base",
        "basic_information_prompt": "Sélectionnez le type de rapport que vous souhaitez créer et entrez les détails requis",
        "frequency": "Fréquence",
        "frequency_prompt": "Sélectionnez la fréquence à laquelle vous souhaitez envoyer le rapport",
        "day": "Jour",
        "daily": "Quotidien",
        "hour": "Heure"
    },
    "sim": {
        "add_sim": "Ajouter une carte SIM",
        "search_iccid": "Rechercher ICCID",
        "iccid_prompt": "L'identifiant unique de la carte SIM",
        "msisdn_prompt": "Le numéro de téléphone à 10-14 chiffres, y compris l'indicatif du pays (par exemple, 44 au Royaume-Uni)",
        "provider": "Fournisseur",
        "send_sms": "Envoyer un SMS",
        "send_sms_prompt": "Envoyez un SMS à",
        "message": "Message",
        "sms_sent": "SMS envoyé avec succès",
        "imsi_description": "Numéro RNIS de la station mobile.",
        "provider_description": "Fournisseur qui a émis la carte SIM. Si votre fournisseur de carte SIM n'est pas répertorié veuillez contacter l'assistance."
    },
    "telematics": {
        "edit_telematics": "Modifier l'unité télématique",
        "no_update_information": "Aucune information de mise à jour",
        "add_telematics": "Ajouter un boîtier télématique",
        "search_imei": "Rechercher IMEI",
        "software": "Logiciel",
        "hardware": "Matériel",
        "main_port": "Port principal",
        "accessory_port": "Port accessoire",
        "device_name": "Nom de l'appareil",
        "remove": "Supprimer l'unité télématique"
    },
    "settings": {
        "profile": "Profil",
        "profile_prompt": "Informations de base concernant votre compte. Pour changer votre mot de passe entrez votre nouveau mot de passe dans le New password",
        "first_name": "Prénom",
        "last_name": "Nom de famille",
        "new_password": "nouveau mot de passe",
        "locale": "Lieu",
        "locale_prompt": "Modifier la façon dont les informations sont affichées sur le site Web. Les unités de langue et de température sélectionnées ici seront également reflétées dans les rapports, par ex. Rapports IMA.",
        "language": "Langue",
        "pressure_unit": "Unité de pression",
        "temperature_unit": "Unité de température",
        "time_zone": "Fuseau horaire",
        "coming_soon": "à venir",
        "update_settings": "Mettre à jour les paramètres",
        "test_sms": "Envoyer un SMS test"
    },
    "not_found_page": {
        "title": "Page non trouvée",
        "subtitle": "Veuillez vérifier l'URL dans la barre d'adresse et réessayer."
    }
}
